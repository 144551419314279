@import "./variables";

// global type
body {
    font-family: $primary-font;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $dark-grey;
    background-color: $light-grey; // should match the footer in case the page is short.
    font-size: 18px;
    font-weight: 400;
}


p {
    line-height: 1.25;
}

.btn {
    text-transform: uppercase;
}

// todo: make sure that every page has proper semantic headers (h1 < h2 < h3 < h4 with no skipping around just for styling)
h1 {
    font-family: $secondary-font;
    font-size: 2em;
    line-height: 1.2;
    font-weight: 100;
    @media (min-width: $screen-sm-min) {
        font-size: 2.5em;
    }
}

h2 {
    font-family: $secondary-font;
    font-size: 1.688em;
    font-weight: 100;
    margin-bottom: 40px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (min-width: $screen-sm-min) {
        font-size: 2.688em;
    }
}

h3 {
    font-size: 1.313em;
    font-weight: 300;
    letter-spacing: 1px;
    @media (min-width: $screen-sm-min) {
        font-size: 25px;
    }

    &.total-price {
        color: $green;
        font-weight: 500;
    }
}

h4 {
    font-size: 1.125em;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media (min-width: $screen-sm-min) {
        font-size: 1.25em; // todo scale the body font at this breakpoint and everything will scale
    }
}

.blue-link-font {
  color: $blue;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.5px;
  font-size: 0.9em;
  transition: 0.5s;

  &:hover,
  &:focus {
    color: $light-blue;
    text-decoration: none;
  }
}

a {
  @extend .blue-link-font
}


.lowercase-link {
    color: $dark-grey;
    letter-spacing: 0;
    text-transform: none;

    &:hover {
        color: $green;
        background-color: transparent;
    }
}

// for use with the oo-colored-rings background card
.blue-card-header {
    font-family: $primary-font;
    color: $blue;
    text-transform: none;
    font-weight: 400;
    margin: 0 0 20px 0;
    letter-spacing: 0;
    font-size: 1.375em;
    @media (min-width: $screen-sm-min) {
        font-size: 1.75em;
    }
}


// cta -> call to action
.bold-cta-header {
    font-size: 1.5em;
    font-family: $primary-font;
    font-weight: 400;
    margin-bottom: 18px;
    line-height: 1.35;
}

.basic-enzo-header-md {
    font-size: 2em;
    line-height: 1.2;
    font-weight: 100;
    font-family: $secondary-font;
}

.basic-enzo-header-sm {
    font-size: 1.5em;
    line-height: 1.2;
    font-weight: 100;
    font-family: $secondary-font;
}


.basic-proxima-header-lg {
    font-size: 2.2em;
    line-height: 1.2;
    font-family: $primary-font;
    font-weight: 300;
}

.basic-proxima-header-md {
    font-size: 1.8em;
    line-height: 1.2;
    font-family: $primary-font;
    font-weight: 300;
}

.basic-proxima-header-sm {
    font-size: 1.35em;
    line-height: 1.2;
    font-family: $primary-font;
    font-weight: 300;
}

.basic-proxima-header-xs {
    font-size: $p-font-size;
    line-height: 1.2;
    font-family: $primary-font;
    font-weight: 300;
}

.bold-proxima-spaced-header {
    font-size: $p-font-size;
    line-height: 1.2;
    font-family: $primary-font;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

// matches the type of font put in a checkbox label...
.checkbox-font {
    font-size: 1em;
    line-height: 24px;
}

// use to make proxima nova fonts bold
.extra-heavy-font {
    font-weight: 600;
}

.heavy-font {
    font-weight: 400;
}

.light-font {
    font-weight: 300;
}

.small-text {
    font-size: .8em;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.space-letters {
    letter-spacing: 1px;
}

a.link-lg {
    font-size: 1.35em;
    letter-spacing: normal;
}

// this font mimics that of the label element
.label-font {
    font-size: .875em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: .5rem;
    line-height: 24px;
}

.hover-light-green {
    &:hover {
        color: lighten($green, 40%);
    }
}
