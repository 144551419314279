@import './variables.scss';
@import './bootstrap/bootstrap.scss';
@import "./toastr";

body {
  background-color: $light;
}

#main-content {
  background-color: $white;
  padding-bottom: 60px;
}

#page-content {
  max-width: 1680px;
  margin: 0 auto;
  overflow-x:hidden;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices. Fix for closing tooltips via data-trigger: focus on ios */
  body {
    cursor: pointer;
  }
}


@import './utilities/common-utils';
@import 'global-type.scss';

@import 'accordion-content.scss';
@import 'banner.scss';
@import 'buttons.scss';
@import 'footer.scss';
@import 'form-inputs.scss';
@import 'helpers.scss';
@import 'modal-popups.scss';
@import './navbar.scss';

@import 'shared/cards.scss';
@import 'shared/oo-backdrop.scss';

// Purchase styles
// todo put these all in their own folder
@import 'kit-purchase.scss';
@import 'kit-purchase-review.scss';
@import 'kit-purchase-promo-code.scss';
@import 'kit-purchase-quantity.scss';

// Activate styles
@import 'activate.scss';
@import 'activate-physician-review.scss';

// Patient login screen
@import 'login.scss';

@import 'my-dashboard/dynamic-banner.scss';
@import 'my-dashboard/progress-bar.scss';

@import 'my-results/my-results.scss';
@import 'sharing.scss';
