@media (min-width: $screen-sm-min) {
    .barcode-section .btn.next-step {
        margin-top: 60px;
    }
}
.barcode-graphic {
    max-width: 180px;
    margin: 0 0 30px 0;
    @media (min-width: $screen-md-min) {
        max-width: 250px;
        margin: 0;
    }
}
.assistance {
    font-size: 14px;
    margin-top: 30px;
}
.swab-video {
    margin: 30px 0 40px 0;
}
.consent-checkbox {
    background: #e5e5e5;
    padding: 20px 20px 10px 20px;
    margin: 0;
    @media (min-width: $screen-xs-min) {
        margin: 30px 0;
    }
}
.consent-section {
    ul {
        margin: 20px 0 10px 0;
    }
    ul li {
        margin: 7px 0;
    }
}
.exclusion-case-text {
    padding-top: 60px;
    padding-bottom: 60px;
    h2 {
        line-height: 1.3;
    }
}
.sample-step {
    margin-bottom: 20px;
    overflow: hidden;
    &:first-child {
        @media (min-width: $screen-xs-min) {
            margin-top: 20px;
        }
    }
    .step-icon {
        float: left;
        width: 130px;
        img {
            width: 100px;
        }
    }
    .step-desc {
        float: left;
        width: calc(100% - 130px);
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
}
