#patient-nav {
  .nav-item.dropdown.show {
    background-color: $light-grey;
    color: $green;

    &>.nav-link {
      color: $green;
    }
  }

  a.nav-link {
    font-size:.9em;
  }

  a.dropdown-item {
    font-size:.9em;
  }

  // wrap the dropdown toggle
  .dropdown-toggle {
    white-space: normal;
  }

  .navbar-nav>.nav-item {
    &:first-child {
      margin-left: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dropdown-menu {
    border-top: none;
    border-radius: 0;
    box-shadow: none;
  }
}
