@for $i from 3 through 15 {
    $mw: $i * 100;
    .mw-#{$mw} {
        max-width: #{$mw}px;
    }
}

@for $i from 7 through 15 {
   $mw: $i * 100;
   .centered-mw-#{$mw} {
      margin: 0 auto;
      max-width: #{$mw}px;
   }
}

.bottom-border-shadow {
   box-shadow: 0px 2px 5px -2px rgba($dark-grey, .2);
}

.oo-box-shadow {
   box-shadow: $oo-box-shadow;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-medium-grey {
   background-color: $medium-grey;
}

/*
With Bootstrap 4 you can use:
@include media-breakpoint-up(xs) { ... }  <-- min-width
@include media-breakpoint-only(xs) { ... }
@include media-breakpoint-between(md, xl) { ... }
@include media-breakpoint-down(xs) { ... } <-- max-width

With the values of xs, sm, md, lg, and xl
*/
