.report-icon {
  width: 100px;
}

.report-container {
  max-width: 1300px;
}

.reports-wrapper {
  max-width: 1300px;
}
