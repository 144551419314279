.accordion-content {
    padding-top: 30px;
    // accordion header
    .heading {
        margin: 10px 0 0 0;
        background: $blue;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        overflow: hidden;
        padding: 25px 15px;
        @media (min-width: $screen-sm-min) {
            padding: 25px;
        }
        .title {
            float: left;
            @media (min-width: $screen-sm-min) {
                font-size: 22px;
            }
        }
        .accordion-right-text {
            float: right;
            font-size: 14px;
            @media (min-width: $screen-sm-min) {
                padding-right: 20px;
                font-size: 22px;
            }
        }
        .accordion-icon {
            width: 70px;
            margin-right: 25px;
            float: left;
            text-align: center;
            img {
                margin: -10px 25px -10px 0;
            }
        }
    }
    // accordion content
    .content {
        padding: 25px 15px;
        border-left: 1px solid $medium-grey;
        border-right: 1px solid $medium-grey;
        border-bottom: 1px solid $medium-grey;
        @media (min-width: $screen-sm-min) {
            padding: 40px 60px;
        }
    }
}

// accordion icons
.accordion-icon {
    img {
        height: 50px;
    }
}
.purchase-section {
    .accordion-icon {
        img {
            height: 30px;
        }
    }
}
.shipping-section {
    .accordion-icon {
        img {
            height: 45px;
        }
    }
}
.payment-section {
    .accordion-icon {
        img {
            height: 45px;
        }
    }
}
.identification-section {
    .accordion-icon {
        img {
            height: 45px;
        }
    }
}
