.dashboard-background {
    @include media-breakpoint-up(lg) {
        background: url('css_images/asset-dashboard-image.jpg');
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

#dynamic-banner {
    @include media-breakpoint-up(lg) {
        border-radius: $standard-border-radius;
        position: relative;
        top: 25px;
        max-width: 520px;
    }
}
