// kit purchase step 1
.purchase-section .col-md-6 {
    margin-bottom: 30px;
    &.kit {
        margin-bottom: 10px;
    }
}
.product-description {
    ul {
        @media (max-width: $screen-xs-max) {
            padding-left: 25px;
        }
    }
    li {
        padding: 3px 0;
        font-size: 16px;
    }
}
.kit img {
    width: 80%;
    margin: 10px 0;
    @media (min-width: $screen-md-min) {
        width: 75%;
    }
}
.purchase-cart {
    background: $light-grey;
    padding: 20px;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1px;
    margin: 40px 0;
    @media (min-width: $screen-md-min) {
        margin: 0 0 40px 0;
        padding: 20px;
    }
    .quantity-number {
        display: none;
    }
    .item {
        margin: 10px 0;
        clear: both;
        overflow: hidden;
    }
    .item-name {
        float: left;
        font-size: 15px;
        @media (max-width: 375px) {
            width: 60%;
        }
        @media (min-width: $screen-xs-min) {
            font-size: 18px;
        }
    }
    .item .price {
        float: right;
        font-size: 15px;
        @media (min-width: $screen-xs-min) {
            font-size: 18px;
        }
    }
    .item.cart-promo-code {
        color: $green;
        display: none;
    }
    .item.final-total {
        margin-top: 30px;
        font-size: 22px;
        font-weight: 600;
    }
}
.eligibility-section {
    background: $light-grey;
    padding: 20px;
    border-top: 2px solid $medium-grey;
    margin-top: 20px;
    @media (min-width: $screen-sm-min) {
        padding: 20px 40px;
    }
}
.eligibility-checkbox {
    padding-left: 30px;
    display: inline-block;
    .checkmark {
        left: -30px;
    }
    .checkmark.checkmark.has-error {
        border-color: $red;
    }
    .checkmark-container {
        padding-left: 0;
        display: inline;
        margin-right: 7px;
    }
    i.fa.fa-question-circle {
        margin-left: 0px;
    }
}
.eligibility-restrictions {
    margin-top: 10px;
}
ul.eligibility-restrictions {
    padding-left: 45px;
    li {
        font-size: 16px;
        padding: 3px 0;
        @media (min-width: $screen-lg-min) {
            padding: 7px 0;
        }
    }
    @media (min-width: $screen-lg-min) {
        list-style-type: disc;
        list-style-position: inside;
    }
}
.eligibility-restrictions-error {
    display: none;
    color: $red;
    font-size: 14px;
    padding-left: 30px;
}

// kit purchase shipping step
.shipping-info {
    display: none;
}

// kit purchase payment step
.payment-section .info-text {
    margin-bottom: 20px;
}
