// form styles
.form-control {
  border: 2px solid #ccc;
  border-radius: 6px;
  height: 39px;
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    font-size: 16px;
  }
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle {
  border-radius: 10px;
}
label, .field-label {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  small {
    font-weight: 400;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 0;
  }
  &.checkmark-container {
    text-transform: none;
    letter-spacing: 0;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
  }
}
.checkbox label {
  text-transform: none;
  letter-spacing: 0;
  padding-left: 0;
  display: inline;
}
.radio label {
  text-transform: none;
  letter-spacing: 0;
  padding-left: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

.help-block {
  font-size: 14px;
}

.has-error {
  .form-control {
    border-color: $red;
  }
  .help-block {
    font-size: 14px;
    color: $red;
    display: block;
  }
  & + .help-block {
    color: $red;
  }
}
#shipping_option_help {
  color: $red;
}

/* Customize the label (the checkmark-container) */
.checkmark-container {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f9f9f9;
  &.checkbox-style {
    border-radius: 4px;
  }
  &.radio-style {
    border-radius: 50%;
  }
  border: 1px solid $dark-grey;
}

// /* On mouse-over, add a grey background color */
// .checkmark-container:hover input ~ .checkmark {
//   background-color: #e7e7e7;
// }
.checkmark-container:focus input ~ .checkmark {
  outline: -webkit-focus-ring-color auto 5px;
}

.checkmark-container input[type='checkbox']:focus + span.checkmark,
.checkmark-container input[type='radio']:focus + span.checkmark {
  outline: 1px dotted ButtonText; // adds default button focus highlighting for styled checkbox/radio in firefox
  outline: -webkit-focus-ring-color auto 5px;
}

/* When the checkbox is checked, add a green background */
.checkmark-container input:checked ~ .checkmark {
  background-color: $green;
  border: 1px solid transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 13px;
  border: 1px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.styled-radio-option .checkmark-container {
  display: inline-block;
}
.styled-radio-option.inline-radio {
  display: inline;
  margin-right: 60px;
}
p.radio-label {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}
.radio-row {
  margin-bottom: 15px;
}

label.validate-error {
  font-size: 14px;
  text-transform: none;
  color: $red;
  font-weight: 400;
  letter-spacing: 0;
}
input.validate-error,
select.validate-error {
  border-color: $red;
}
#shipping-option-help {
  display: none;
  font-size: 14px;
  color: $red;
}
.error {
  color: $red;
}
.panel.form-error .panel-body {
  color: $red;
  text-align: center;
  font-size: 18px;
}
.consent-box {
  background: #e5e5e5;
  margin: 20px 0;
  padding: 20px 20px 10px 20px;
}
.combodate {
  display: block;
}
.combodate-inline {
  display: inline;
  min-width: 60px;
  @media (min-width: $screen-lg-min) {
    min-width: 90px;
  }
}

.form-control.review[disabled] {
  background: $white;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  height: auto;
}
.tooltip-inline {
  label {
    display: inline;
  }
  .select2,
  select,
  input,
  p {
    margin-top: 5px;
  }
  .radio-list {
    margin-top: 11px;
  }
}
