$background-image-width: 990px;

.oo-white-rings-background {
    background: url(css_images/white-rings-background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 50%;
    @media (max-width: $background-image-width) {
        background-image: $green-to-blue-gradient !important;
    }
    // remove the margin between the content and the padding of the base template..
    margin-bottom: -60px;
}

.oo-colored-rings-background {
    background: url(css_images/colored-rings-background.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 50%;
    @media (max-width: $background-image-width) {
        background-image: $green-to-blue-gradient !important;
    }
    // remove the margin between the content and the padding of the base template..
    margin-bottom: -60px;
}

.gradient-bg {
    background-image: linear-gradient(to right,#2bb673 0,#00d1b2 31%,#27aae1 100%) !important;
}
