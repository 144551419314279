// buttons
.btn {
  border-radius: 10px;
}



// todo: BEMify
.submit-buttons {
  text-align: center;
  margin: 30px 0;
  @media (min-width: $screen-sm-min) {
    text-align: right;
    margin: 50px 0 30px 0;
  }
  .btn {
    min-width: 160px;
    &.go-back {
      margin-right: 7px;
      @media (max-width: $screen-xs-max) {
        min-width: 120px;
      }
    }
    &.go-back + .next-step {
      @media (max-width: $screen-xs-max) {
        min-width: 120px;
      }
    }
  }
  &.text-center-mobile {
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }
}
.btn-submit {
  min-width: 160px;
}

i.fa {
  color: #979797;
  outline: none;
}
i.fa.fa-question-circle {
  font-size: 20px;
  margin-left: 7px;
  cursor: pointer;
}


// Custom Button Classes!!

// "Default"
.btn.btn-default {
  background: #fff;
  border: 2px solid #707070;
  color: #707070;
  margin: 0 0;
}
.btn.btn-default:hover {
  background-color: #707070;
  color: #fff;
}


// Outline Link
.btn.btn-outline-link {
  color: $green;
  background-color: $white;

  &:hover {
    background-color: #e5e5e5;
  }
}

.btn.btn-mock-link {
    color: $blue;
    background-color: $white;
    &:hover {
        color: $light-blue;
    }
}


// Ghost Link
.btn.btn-ghost-link {
  background-color: transparent;
  color:$white;
  border-color: $white;

  &:hover {
    color: $green;
    background-color: $white;
  }
}
