// footer styles
footer {
    h3 {
        font-size: 1.125em;
        @media (min-width: $screen-sm-min) {
            font-size: 1.25em;
        }
    }

    .contact-info-font {
        letter-spacing: 1px;
        font-size: 1em;
        @media (min-width: $screen-sm-min) {
            font-size: 1.125em;
        }
        line-height: 1.1;
    }

}