// To compile sass run npm install and gulp within src/oneome.exp/oneome/exp
// colors
$blue: #27aae1;
$light-blue: #93d4f0;
$green: #2bb673;
$light-green: #95dab9;
$white: #ffffff;
$light-grey: #f5f5f5;
$grey: #f1f1f2;
$medium-grey: #ccc;
$medium-dark-grey: #b7b7b7;
$dark-grey: #606060;
$red: #be1e2d;
$green-to-blue-gradient: linear-gradient(to right,$green 0,#00d1b2 31%,$blue 100%);
$oo-box-shadow: 0px 0px 30px 7px rgba($dark-grey, .2); // mandated box shadow

$primary: $blue; //overright bootstrap's blue
$success: $green;
$secondary: $dark-grey;
$info: $light-blue;
$danger: $red;
$light: $grey;

// breakpoints
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

// typography
$primary-font: 'proxima-nova', Helvetica, Arial, sans-serif;
$secondary-font: 'ff-enzo-web', Helvetica, Arial, sans-serif;


$navbar-light-hover-color: $green;
$dropdown-link-hover-color: $green;
$dropdown-link-active-bg: transparent;
$dropdown-link-active-color: $green;

$standard-border-radius: 10px;

$p-font-size: 1.125em;
