.default-shadow {
    -webkit-box-shadow: 6px 10px 38px rgba(0,0,0,0.08);
    box-shadow: 6px 10px 38px rgba(0,0,0,0.08);
}
.row-v-spacing {
    margin: 60px 0;
}
.row-v-spacing-top {
    margin-top: 60px;
}
.row-v-spacing-bottom {
    margin-bottom: 60px;
}
