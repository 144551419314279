// promo code section
.promo-code {
  .form-control {
    @media (min-width: 320px) {
      max-width: 160px;
    }
  }
}
.promo-code-message {
  color: $green;
  margin-top: 5px;
  font-size: 14px;
  &.error {
    color: $red;
  }
}
.promo-removed {
  color: $dark-grey;
}
