.sharing-person-box {
    border: 1px solid $medium-grey;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    height: 100%;

    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .phone-icon-before {
        background-image: url("css_images/icons/asset-phone.svg");
        background-size: 20px auto;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 30px;
    }

    .email-icon-before {
        background-image: url("css_images/icons/asset-email.svg");
        background-size: 20px auto;
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 30px;
    }

    // The two week warning makes things red...
    &.sharing-person-box--error {
        border-color: $red;
        .sharing-person-box__expire_text {
            color: $red;
        }
        .sharing-person-box__expiration {
           color: $red;
            font-weight: 600;
        }
    }
}


