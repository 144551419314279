// quantity section
.quantity {
    margin-bottom: 30px;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    .item-name {
        width: auto;
        float: left;
        margin-top: 10px;
    }
    .quantity-control {
        float: right;
    }
}
.quantity-control {
    display: table;
    padding: 5px;
}
.quantity-button {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
    svg {
        display: block;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    &.decrease {
        &:not(.disabled) .decrease-path {
            fill: $green;
        }
    }
}
.quantity-control-number {
    display: table-cell;
    vertical-align: middle;
    padding: 0 0.5em;
    min-width: 40px;
    text-align: center;
    font-size: 16px;
    @media (min-width: $screen-xs-min) {
        font-size: 18px;
    }
}
.quantity-message {
    text-transform: none;
    letter-spacing: 0;
    font-size: 14px;
    clear: both;
    padding-top: 10px;
    display: none;
    @media (min-width: $screen-xs-min) {
        text-align: right;
    }
}
