.edit-mode {
    display: none;
}
.edit {
    float: right;
    margin-right: 10px;
    margin-top: 5px;
    color: $blue;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    cursor: pointer;
    @media (min-width: $screen-md-min) {
        margin-right: 250px;
    }
    &:hover,
    &:focus {
        color: $light-blue;
    }
}
.read-mode {
    p {
        margin-bottom: 10px;
    }
    @media (max-width: $screen-xs-max) {
        margin-right: 60px;
    }
}
