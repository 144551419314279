// banner
.logo {
    padding: 20px 0;
    img {
        width: 130px;
        @media (min-width: $screen-sm-min) {
            width: 180px;
        }
    }
    h1 {
        font-size: 24px;
        margin-top: 2px;
        @media (min-width: $screen-sm-min) {
            margin: 4px 0 0 0;
            font-size: 32px;
        }
    }
}
.banner {
    background: $grey;
    padding: 30px 0;
    h1 {
        margin: 0;
        @media (max-width: $screen-xs-max) {
            padding-left: 5px;
        }
    }
}


.header-banner {
    text-align: center;
    background-color: $green;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    
    a {
        color: white;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    p {
        margin-bottom: 0;
    }
}