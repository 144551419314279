// kit purchase review step
.review-step {
    margin: 25px 0;
}
.kit-review {
    @media (min-width: $screen-sm-min) {
        margin-top: 15px;
    }
}
.review-section h4 {
    margin-top: 0;
}
.review-edit-button {
    cursor: pointer;
    text-transform: uppercase;
    color: $blue;
    &:hover,
    &:focus,
    &:active {
        color: $light-blue;
    }
    @media (min-width: $screen-md-min) {
        margin-right: 250px;
    }
}
.review-step p {
    margin: 0 0 3px;
    font-size: 16px;
}
.review-ship-to-other.subheading {
    text-transform: uppercase;
    margin: 20px 0 7px;
    letter-spacing: 1px;
}
.review-payment-cart {
    margin: 20px 0;
    @media (min-width: $screen-md-min) {
        margin-right: 250px;
    }
    .item {
        margin: 15px 0;
    }
    .services {
        margin: 5px 0;
        text-transform: none;
        letter-spacing: 0;
        .item-name {
            font-size: 13px;
            @media (max-width: 375px) {
                width: 70%;
            }
            @media (min-width: $screen-sm-min) {
                font-size: 15px;
            }
        }
        .price {
            font-size: 13px;
            margin-top: 4px;
            @media (min-width: $screen-sm-min) {
                font-size: 15px;
            }
        }
    }
    .item.cart-promo-code {
        color: $green;
        display: none;
    }
    .item.review-promo-code {
        color: $green;
    }
    .item.rightmed-test {
        margin: 0;
    }
    .final-total {
        .item-name,
        .price {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
            @media (min-width: $screen-md-min) {
                font-size: 20px;
            }
        }
        .item-name {
            @media (max-width: 375px) {
                width: 50%;
            }
        }
    }
    i.fa {
        margin-left: 5px;
    }
}
