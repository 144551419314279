$alpha : .3;

.simple-card {
    background: $white;
    border-radius: $standard-border-radius;
    margin-bottom: 36px;
    margin-top: 36px;
    padding: 36px 22px;
    @media (min-width: $screen-sm-min) {
        margin: 120px 0;
        padding: 44px 56px;
    }
    -webkit-box-shadow: $oo-box-shadow;
    -moz-box-shadow: $oo-box-shadow;
    box-shadow: $oo-box-shadow;
}

.simple-card__header {
    font-size: 2em;
    margin: 24px auto;
    margin-bottom: 48px;
    text-align: center;
    max-width: 300px;
}

.simple-card__oo-logo {
    margin: 0 auto;
    width: 50%;
    max-width: 168px;
    display: block;
}

.simple-card__spacing-container {
    margin-bottom: 1.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.simple-card__aesthetic-break {
    margin: 24px*1.75 auto;
    width: 80%;
    max-width: 500px;
    border-bottom: 1px solid $medium-grey;
    height: 1px;
}

