.progress-indicator-dot {
    height: 20px;
    width: 20px;
    border: 1px $white solid;
    border-radius: 10px;
    background-color: $medium-dark-grey;
    margin: 0 auto;
    z-index: 2;
    position: relative;
}

.progress-bar {
    height: 6px;
    position: absolute;
    width: 100%;
    background-color: $medium-dark-grey;
    top: 7px;
    right: 50%;
    z-index: 1;
}

