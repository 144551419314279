// popup alerts
.modal-footer {
    border-top: none;
}


// This is a special modal because it has an icon in the header...
#payment-failure-modal {
    .modal-dialog {
        @media (min-width: $screen-sm-min) {
            width: 450px;
        }
    }
    img.popup-error-image {
        width: 100px;
        height: 64px;
        float: left;
        margin-right: 20px;
    }
    .close {
        font-size: 40px;
    }
    .modal-content {
        border-radius: 10px;
    }
    .modal-header {
        border-bottom: none;
        padding-bottom: 0;
    }
    .modal-body {
        padding: 0 40px;
    }
    h3 {
        text-transform: none;
        color: $red;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 0;
        font-weight: 500;
        @media (min-width: $screen-sm-min) {
            color: $dark-grey;
        }
    }
    h3 + p {
        clear: both;
        padding-top: 20px;
    }
    .modal-cta {
        margin: 40px 0;
    }
    .btn {
        min-width: 160px;
    }
}
